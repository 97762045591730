import React from "react";
import Footer from "src/components/Footer_en";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
          
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/en/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/en/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/en/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/ultra_light/">Ultra Light</a>
                      </li>
                      <li>
                        <a href="/en/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/en/our-products/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/en/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/en/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/en/our-products/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/en/our-products/all_products/">
                          All products
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/en/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/en/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/legal-notice/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/legal-notice/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/aviso-legal/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/aviso_legal/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/legal-notice/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/legal-notice/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h2>LEGAL NOTICE</h2>
                <p>
                  <strong>All content</strong> of this Internet site is owned
                  and controlled by JNTL Consumer Health I (Switzerland) GmbH
                  and is protected by worldwide copyright laws. You may download
                  content only for your personal use for non-commercial purposes
                  but no modification or further reproduction of the content is
                  permitted. The content may otherwise not be copied or used in
                  any way.
                </p>
                <p>
                  <strong>The owners</strong> of this site will use reasonable
                  efforts to include up-to-date and accurate information in this
                  Internet site, but make no representations, warranties or
                  assurances as to the accuracy, currency or completeness of the
                  information provided. The owners of this site shall not be
                  liable for any damages or injury resulting from your access
                  to, or inability to access, this Internet site, or from your
                  reliance on any information provided at this Internet site
                </p>
                <p>
                  <strong>Intended Audience:</strong> This site is intended for
                  an audience residing in the following countries: Italy,
                  France, Norway, Denmark, Sweden, and The Netherlands.
                </p>
                <p>
                  <strong>This Internet site</strong> may provide links or
                  references to other sites but the owners of this site have no
                  responsibility for the content of such other sites and shall
                  not be liable for any damages or injury arising from that
                  content. Any links to other sites are provided merely as a
                  convenience to the users of this Internet site.
                </p>
                <p>
                  <strong>The trademarks</strong>, service marks, trade names,
                  trade dress and products in this Internet site are protected
                  in Italy, France, Norway, Denmark, Sweden, The Netherlands and
                  internationally. No use of any of these may be made without
                  the prior, written authorisation of the owners of this
                  Internet site, except to identify the products or services of
                  the company.
                </p>
                <p>
                  <strong>Any personally</strong> identifiable information in
                  electronic communications to this Internet site is governed by
                  this site's{" "}
                  <a href="/en/legal/private-policy/">Privacy Policy</a>. The
                  owners of this site shall be free to use or copy all other
                  information in any such communications including any ideas,
                  inventions, concepts, techniques or know-how disclosed
                  therein, for any purposes. Such purposes may include
                  disclosure to third parties and/or developing, manufacturing
                  and/or marketing goods or services.
                </p>
                <p>
                  The sender of any communications to this Internet site or
                  otherwise to the owners of this site shall be responsible for
                  the content and information contained therein, including its
                  truthfulness and accuracy.
                </p>
                <p>
                  <em>
                    <br />
                  </em>
                </p>
                <p>
                  <em>Company Information</em>
                </p>
                <p>
                  <strong>
                   JNTL Consumer Health I (Switzerland) GmbH
                    <br />
                  </strong>
                  Gubelstrasse 34, CH-6300 Zug
                  <br />
                  Commercial Register, Number: CH-170.3.010.696-2
                </p>
                <p> </p>
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
